<template>
    <div class="flex flex-col min-h-screen overflow-hidden">
  
      <!-- Site header -->
      <SiteHeader />
  
      <!-- Page content -->
      <main class="flex-grow">
  
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="pt-32 pb-12 md:pt-2 md:pb-2">
          <div class="max-w-3xl mx-auto lg:max-w-none">

            <article>
            <!-- button back to blog --> 
            


            <!-- Article header -->
            <header class="max-w-3xl mx-auto mb-20">
                        
    <div class="blog">


<nav class="flex" aria-label="Breadcrumb">
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
        <router-link :to="'/'+session" tag="a" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
            {{session | removeHyphens | capitalizeAll}}
        </router-link>
        <svg class="rtl:rotate-180 w-3 h-3 ml-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
            {{ title | removeHyphens | capitalizeAll}}
        </span>
      </div>
    </li>
  </ol>
</nav>

        <!-- <router-link :to="'/tag/'+session" tag="a">&laquo; Back</router-link> -->
        <router-view />
        <div id="toc"></div>
        <router-link :to="'/'+session" tag="a">&laquo; Back</router-link>
    </div>

</header>
</article>

    </div>
    </div>
    </div>
  
      </main>
  
      <!-- Site footer -->
      <Footer />
  
    </div>
  </template>
  
  <script>
  import SiteHeader from './../partials/Header.vue'
  import Footer from './../partials/Footer.vue'
  
  export default {
    name: 'BlogPost',
    data() {
      return {
        session: 'blog',
        title: ''
      }
    },
    components: {
      SiteHeader,
      Footer,
    },
    filters: {
      removeHyphens: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.replace(/-/g, ' ')
      },
      capitalizeAll: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    },
    mounted() {
        this.session = this.$route.path.split('/')[1]
        this.title = this.$route.path.split('/')[2]
    },
    head: {
        // To use "this" in the component, it is necessary to return the object through a function
        // title: function () {
        //     return {
        //         inner: this.title
        //     }
        // },
        // meta: [
        //     { name: 'description', content: 'My description', id: 'desc' }
        // ]
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .blog {
    max-width: 50vw;
    margin: 6rem auto;
  
    .back {
      background-color: #42b883;
      color: white;
      margin-top: 5rem;
      text-decoration: none;
      padding: 10px 15px;
      border: 1px solid currentColor;
      border-radius: .5rem;
      display: inline-block;
      transition: all .3s ease;
      &:hover {
        background-color: transparent;
        color: #42b883;
      }
    }
    
      h1 {
        font-size: 3rem;
        margin-bottom: .2rem;
        color: #42b883;
      }
      h4 {
        margin-bottom: 3rem;
        color: #35495e;
      }
      pre {
        overflow-x: auto;
        background-color: #35495e;
        color: black;
        border-radius: .3rem;
        padding: 1rem;
      }
      img {
        max-width: 100%;
      }
    }
  
  </style>